@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
}

html, body {
  min-width: 320px;
}

#root {
  min-height: 100%; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

video::-internal-media-controls-overlay-cast-button {
  display: none;
}

@keyframes gamecrown {
  0% {
    bottom: 124px;
  }
  50% {
    bottom: 120px;
  }
  100% {
    bottom: 124px;
  }
}

@keyframes gamecash {
  0% {
    transform: rotate(-30deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-30deg);
  }
}

@keyframes gamelucky {
  0% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

@keyframes gamegirl {
  0% {
    transform: scale(1);
    left: 20px;
  }
  50% {
    transform: scale(1.05);
    left: 10px;
  }
  100% {
    transform: scale(1);
    left: 20px;
  }
}

@keyframes gametotem {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

@keyframes gametaxi {
  0% {
    bottom: 0
  }
  50% {
    bottom: 5px;
  }
  100% {
    bottom: 0
  }
}

@keyframes gamecard1 {
  0% {
    opacity: 1;
    transform: rotate(0deg);
    top: -10px;
  }
  25% {
    opacity: 1;
    transform: rotate(0deg);
    top: -10px;
  }
  50% {
    opacity: 0;
    transform: rotate(20deg);
    top: 70px;
  }
  75% {
    opacity: 1;
    transform: rotate(0deg);
    top: -10px;
  }
  100% {
    opacity: 1;
    transform: rotate(0deg);
    top: -10px;
  }
}

@keyframes gamecard2 {
  0% {
    opacity: 1;
    transform: rotate(0deg);
    top: -10px;
  }
  25% {
    opacity: 1;
    transform: rotate(0deg);
    top: -10px;
  }
  50% {
    opacity: 0;
    transform: rotate(-20deg);
    top: 70px;
  }
  75% {
    opacity: 1;
    transform: rotate(0deg);
    top: -10px;
  }
  100% {
    opacity: 1;
    transform: rotate(0deg);
    top: -10px;
  }
}



